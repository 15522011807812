<template>
    <div>
        <div
            class="h4 mb-4 text-gray-700"
        >
            {{ $t('terms.titleTermsAcepted') }}
        </div>
        <div
            class="h6 mb-4 text-gray-600"
        >
            {{ $t('terms.subtitleTermsAcepted') }}
        </div>

        <termsAccepted :accepted-terms="acceptedTerms" />
    </div>
</template>

<script>
import termsAccepted from '@/components/terms/termsAccepted'
import axios from '@/plugins/axios'
export default {
    components: { termsAccepted },
    data() {
        return {
            acceptedTerms: []
        }
    },
    created() {
        this.fetchAcceptedTerms();
    },
    methods: {
        async fetchAcceptedTerms() {
            try {
                const terms = await axios
                    .get('acceptedTerms');

                if (terms.data?.acceptedTerms?.length) {
                    this.acceptedTerms = terms.data.acceptedTerms;
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>
