<template>
    <div class="d-flex flex-column">
        <v-card
            v-for="item in acceptedTerms"
            :key="item.id"
            class="mt-3"
            @click="showTerm(item)"
        >
            <v-card-text class="d-flex flex-column">
                <a>{{ item.title }}</a>
                <span class="text-right">{{ dateHelper.getDateTimeToFormatAndLocale(item.updated_at) }}</span>
            </v-card-text>
        </v-card>

        <confirm-dialog
            ref="confirm"
        />
    </div>
</template>

<script>
export default {
    name: 'TermsAccepted',
    props: {
        acceptedTerms: {
            type: Array,
            required: true
        }
    },
    methods: {
        async showTerm(item) {
            await this.$refs.confirm.open(
                item.title,
                item.body,
                { noCancel: true }
            )
        }
    }
}
</script>

<style scoped>

</style>
